@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Yatra+One:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=candal:wght@700&display=swap");

.serif {
  font-family: "Candal", sans-serif;
  font-weight: 700;
}

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.home-name {
  font-size: 120px;
}
